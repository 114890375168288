.modalTitle {
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .closeIcon {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .modalButtonDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
  }
  
  .modalButton {
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
  
    background: #9747ff;
    border-radius: 100px;
  }
  .modalTitleJournal {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 135%;
    /* or 32px */
  
    letter-spacing: -0.01em;
  
    /* Main/Black */
  
    color: #190d1a;
  
    /* Inside auto layout */
  
    flex: none;
    order: 0;
    flex-grow: 0;
    word-wrap: break-word;
    word-break: break-word;
    display: -webkit-box;
    width: 100%;
  }
  .planModalContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    height: calc(100% - 100px);
    overflow-y: auto;
    gap: 12px;
  }
  
  .modalBottomButton {
    width: calc(100% - 80px);
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    position: absolute;
    left: 40px;
    bottom: 40px;
    border-radius: 100px;
    background: var(--main-purple, #9747ff);
  }
  .planJournalButton {
    display: flex;
    flex-direction: column;
    gap: 6px;
    border-radius: 16px;
    background: var(--main-white, #fff);
    width: 100%;
  }
  .planJournalButton1 {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    border-radius: 16px;
    border: 1px solid var(--main-stroke, #f3f3f3);
    background: var(--main-white, #fff);
    width: 100%;
    padding: 16px;
  }
  .modalBottomButton1 {
    display: flex;
    padding: 16px 40px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    position: absolute;
    right: 272px;
    bottom: 40px;
    border-radius: 100px;
    background: var(--main-purple, #9747ff);
  }
  .planDayData {
    display: flex;
    padding-bottom: 0px;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    position: relative;
  }
  .frame4438 {
    display: flex;
    height: 24px;
    padding: 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: var(--main-background, #f9f9f9);
  }
  .frame4437 {
    display: flex;
    height: 24px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: var(--main-white, #9747ff);
    z-index: 1;
  }
  .frame4439 {
    display: flex;
    width: 56px;
    height: 56px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 2px solid var(--main-purple-tertiary, #f7f2fe);
    background: var(--main-white, #fff);
  }
  .frame4490 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
  }
  .journalButton {
    height: 28px;
    background: #9747ff;
    border-radius: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */
  
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
  
    /* Main/White */
  
    color: #ffffff;
  }
  .dahedLine {
    width: 2px;
    height: 100%;
    position: absolute;
    left: 11px;
    bottom: 0;
    border: 1px dashed var(--main-purple-secondary, #e5d5fc);
    z-index: 0;
    /* background: linear-gradient(to right, currentColor 0%, currentColor 50%, transparent 50%, transparent 100%) repeat left bottom;    
  background-size: 2px 5px; */
  
    /* border-style: dashed 25 1;
      border-width: 7px;
      border-color: red; */
  
    /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='9' stroke-dasharray='5%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e"); */
  }
  .frame4491 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 16px;
    flex: 1 0 0;
    border-radius: 20px;
    border: 1px solid var(--main-stroke, #d0ced0);
    background: var(--main-white, #fff);
  }
  .stepper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    height: calc(100% - 100px);
    overflow-y: auto;
  }
  
  .step {
    display: flex;
    align-items: center;
    gap: 16px;
    align-self: stretch;
  }
  .counter {
    display: flex;
    width: 28px;
    height: 28px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: var(--main-purple, #9747ff);
    position: relative;
    z-index: 2;
  }
  .quote {
    display: flex;
    padding: 24px 64px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex: 1 0 0;
    border-radius: 20px;
    background: var(--main-purple, #9747ff);
    position: relative;
  }
  .dataContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    z-index: 1;
  }
  
  .dataContentButton {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 100px;
    background: var(--main-white, #fff);
  }
  .dataContentButton:hover {
    background: var(--main-white, #fff);
  }
  
  .frame4516 {
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    background: #ac6cff;
  }
  
  .planActionGroup {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    border-radius: 20px;
    border: 2px dashed var(--main-purple-secondary, #e5d5fc);
    background: var(--main-white, #fff);
  }
  
  .planAction {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    align-self: stretch;
  }
  
  .backgroundChip {
    background: #f7f2fe;
    border-radius: 100px;
    color: #9747ff;
    height: 20px;
  }
  .backgroundChip .MuiChip-avatar {
    /* margin-right: -16px; */
  }
  .backgroundChip img {
    height: 12px;
    width: 12px;
  }
  .backgroundChip span {
    font-size: 0.75rem;
  }
  
  .tag {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 100px;
    background: var(--main-purple-tertiary, #f7f2fe);
  }
  .tag1 {
    display: flex;
    padding: 8px 12px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  
    border-radius: 100px;
    background: var(--main-purple-tertiary, #f7f2fe);
  }
  
  .tag1Inner {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .connector {
    display: flex;
    width: 28px;
    height: 344px;
    padding: 0px 8px 0px 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -20px;
    z-index: 1;
  }
  
  .blurOverlay {
    width: 100%;
    height: 90px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: var(--main-white, #fff);
    filter: blur(47px);
  }
  
  .stepper1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    height: calc(100%);
    overflow-y: auto;
    padding-bottom: 60px;
  }
  .inviteBlock {
    display: flex;
    width: calc(100%);
    flex-direction: column;
    align-items: center;
    gap: 16px;
    background: var(--main-white, #fff);
  }
  .CTAButtonGroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
  
    width: 152px;
    height: 88px;
  }
  .CTAButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 12px;
  
    width: 64px;
    height: 88px;
    cursor: pointer;
  }
  
  .CTAIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
  
    width: 64px;
    height: 64px;
  
    background: linear-gradient(135.76deg, #ddf4ff 0%, #bbe8fb 103.63%);
    border-radius: 100px;
  }
  
  .CTAText {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */
  
    text-align: center;
    letter-spacing: -0.01em;
  
    /* Main/Black */
  
    color: #190d1a;
  }
  .cel_info {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    align-self: stretch;
    flex-direction: column;
    gap: 20px;
  }
  .cel_info1 {
    display: flex;
    align-items: center;
    align-self: stretch;
    flex-direction: column;
    gap: 12px;
  }
  
  .cel_info_button {
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 100px;
    background: var(--main-purple, #9747ff);
  }
  @keyframes strike{
    0%   { width : 0; }
    100% { width: 100%; }
  }
  .strike {
    position: relative;
  }
  .strike::after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: black;
    animation-name: strike;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards; 
  }
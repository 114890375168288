.topContainerWrapper {
  width: 100%;
}

.middleContainerWrapper {
  /* width: 100%; */
  /* width: 457px; */
  /* min-width: 250px; */
  /* background: yellow; */
}

.bottomContainerWrapper {
  width: 100%;
}

.questionCount {
  color: #9747ff;
}

.thumbIconsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

.answerWrapper {
  width: 100%;
}

.sliderCustomLabel {
  background: yellow;
}

/* buttons */

.bottomContainerWrapper {
  /* background: yellow; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonWrapper {
  max-width: 457px;
}

.previousButton {
  padding: 18px 20px;
  width: 100%;
  border: 1px solid #9747ff;
  color: #9747ff;
  background: #f9f9f9;
  border-radius: 16px;
}

.previousButton:disabled {
  border: none !important;
}

.previousButton:hover {
  /* border: 1px solid #9747ff;
  color: #ffffff;
  background: #9747ff; */

  border: 1px solid #9747ff;
  color: #9747ff;
  background: #ffffff;
}

.nextButton {
  padding: 18px 20px;
  width: 100%;
  /* border: 1px solid #9747FF; */
  border-color: #9747ff;
  background: #9747ff;
  border-radius: 16px;
  color: #ffffff;
}
.nextButton:disabled {
  background-color: #f9f9f9;
  color: rgba(0, 0, 0, 0.26);
  border: none !important;
}
.nextButton:hover {
  /* background: pink; */
  /* border: 1px solid #9747ff;
  color: #9747ff;
  background: #ffffff; */

  border: 1px solid #9747ff;
  color: #ffffff;
  background: #9747ff;
}

.iconOfProgress {
  /* position: absolute; */

  /* Main/White */

  border: 2px solid #ffffff;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  font-size: 7px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 5;
}

.Imageoverlay {
  position: relative;
}
.Imageoverlay::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 99%;
  width: 100%; /* set to 100% for full overlay width */
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
  border-radius: 32px;
}

.Imageoverlay img {
  width: 100%;
  max-height: 260px;
  border-radius: 32px;
  object-fit: cover;
}

.dataCOntent {
  display: flex;
  width: 612px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.stepprr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  position: relative;
}
.connector {
  width: 2px;
  height: 178px;
  position: absolute;
  top: 30px;
  left: 13px;
  z-index: 1;
  border: 1px dashed #e5d5fc;
}

.stepperItem {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  z-index: 2;
}
.counter {
  display: flex;
  width: 28px;
  height: 28px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: var(--main-purple, #9747ff);
}
.counterLayout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  align-self: stretch;
}
.counterCOntent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}
.counterCOntentTitle {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.confirmButton {
  display: flex;
  padding: 19px 20px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 100px;
}

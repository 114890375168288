.StepFiveWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0 40px;
}
.StepFiveSubWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 16px auto;
  overflow-x: auto;
  max-height: calc(100vh - 340px);
  position: relative;
}
.goalActionInput {
  /* margin: 12px 0px 32px 0px; */
}
.goalActionInput
  .MuiInputBase-formControl:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: #e5d5fc !important;
  border-width: 1px;
}
/* save */
.nextButton {
  width: 100%;
  max-width: 456px;
  border-color: #9747ff;
  background: #9747ff;
  border-radius: 16px;
  color: #ffffff;
  font-size: 1rem;
}
.nextButton:hover {
  border: 1px solid #a25bfe;
  color: #ffff;
  background: #a25bfe;
}
.nextButton:disabled {
  background: #f9f9f9;
  border: none !important;
}
.active {
  background: #9747ff;
  border: 1px solid #9747ff;
}
.progressBarWrapper {
  width: 100%;
  display: flex;
  margin-top: 24px;
  justify-content: center;
  gap: 8px;
}
.progressTittle {
  width: 123px;
  text-align: center;
  color: #000000;
}
.customActionLevelGroup {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 4px;
  align-self: stretch;
  background-color: #f9f9f9;
  border-radius: 8px;
}
.toggleButton {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 12px;
  background: #f9f9f9;
  color: #190d1a;
  fill: #190d1a;
  stroke: #190d1a;
}
.customActionLevelGroup button {
  border: none;
  gap: 5px;
  font-size: 0.85rem !important;
  margin: 5px 3px;
}

.selectedtoggleButton path {
  fill: #9747ff;
}
.selectedtoggleButton {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 72px 0px rgba(25, 13, 26, 0.04);
  color: #9747ff;
  fill: #9747ff;
  stroke: #9747ff;
  background-color: #fff !important;
}
.toggleButton path {
  fill: #190d1a;
}

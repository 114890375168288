.stepOneWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0 40px;
}
.AccordianSummery {
  margin: 0px 0px !important;
  min-height: 48px !important;
}
.nextButton {
  /* padding: 18px 20px; */
  width: 100%;
  max-width: 560px;
  border-color: #9747ff;
  background: #9747ff;
  border-radius: 16px;
  color: #ffffff;
  font-size: 1rem;
}
.nextButton:hover {
  /* background: pink; */
  border: 1px solid #a25bfe;
  color: #ffff;
  background: #a25bfe;
}
.nextButton:disabled {
  background: #f9f9f9;
  border: none !important;
}

/* liked growth */
.customRadio {
  width: 100%;
  display: flex;
  border: 1px solid #f3f3f3;
  border-radius: 16px;
  justify-content: space-between;
  padding: 16px;
  margin: 12px 0px;
}
.checkBoxText {
  padding: 0px 11px;
  display: flex;
  align-items: center;
  color: black;
}
.pinkRadioImg {
  width: 14px;
  height: 12px;
}
.active {
  background: #9747ff;
  border: 1px solid #9747ff;
}
.progressBarWrapper {
  width: 100%;
  display: flex;
  margin-top: 24px;
  justify-content: center;
  gap: 8px;
}
.progressTittle {
  width: 123px;
  text-align: center;
  color: #000000;
}

.overFlowHeigth {
  height: 490px;
}
.overFlowHidden {
  height: 0;
}
.emptyResult {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
}

.frame3979 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.createGoalsBtn {
  display: flex;
  padding: 19px 20px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border: 1px solid #9747ff;
  color: #fff;
  background-color: #9747ff;
  border-radius: 12px;
}

.mainEmptyBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px;
  gap: 16px;

  width: 100%;
  /* height: 458px; */

  /* Main/White */

  background: #ffffff;
}

.emptyContentBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 40px;

  width: 100%;
  /* height: 276px; */
}

.emptyActionButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 16px;
  isolation: isolate;

  width: 100%;
}

.illuBox {
  position: relative;
  min-height: 176px;
}

.emptyTextBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: 100%;
  /* height: 60px; */
}

.rectangle {
  box-sizing: border-box;

  position: absolute;
  width: 156px;
  height: 176px;
  left: calc(50% - 156px / 2);
  top: 0px;

  /* Gradient/Purple */

  background: linear-gradient(135.69deg, #f1edff 100%, #d5cafb 107.13%);
  /* background: linear-gradient(135.69deg, #f1edff 100%, #d5cafb 50%, #d5cafb 100%); */
  border: 8px solid rgba(255, 255, 255, 0.5);
  border-radius: 24px;
}
.illuCheckboxContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  position: relative;
  width: 116px;
  height: 136px;
  left: 0;
  top: 20px;
}
.frameWithCheckboxChecked {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  width: 116px;
  height: 16px;
}

.emptycheckbox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px;
  gap: 10px;

  width: 16px;
  height: 16px;

  background: #ffffff;
  border-radius: 4px;
}

.emptyinnercheckbox {
  width: 12px;
  height: 12px;
  display: flex;
    align-items: center;
    justify-content: center;
}

.zeroindexbarlines {
  width: 92px;
  height: 16px;
  position: relative;
}

.zeroBar1 {
  position: absolute;
  width: 50px;
  height: 4px;
  left: 0px;
  top: 8px;

  /* Main/White */

  background: #ffffff;
  border-radius: 30px;
}
.zeroBar2 {
  position: absolute;
  width: 80px;
  height: 4px;
  left: 0px;
  top: 0px;

  /* Main/White */

  background: #ffffff;
  border-radius: 30px;
}

.oneBar1 {
  position: absolute;
  width: 20px;
  height: 4px;
  left: 42px;
  top: 8px;

  /* Main/White */

  background: #ffffff;
  border-radius: 30px;
}

.oneBar2 {
  position: absolute;
  width: 40px;
  height: 4px;
  left: 0px;
  top: 8px;

  /* Main/White */

  background: #ffffff;
  border-radius: 30px;
}
.oneBar3 {
  position: absolute;
  width: 70px;
  height: 4px;
  left: 0px;
  top: 0px;

  /* Main/White */

  background: #ffffff;
  border-radius: 30px;
}

.twoBar1 {
  position: absolute;
  width: 68px;
  height: 4px;
  left: 20px;
  top: 8px;

  /* Main/White */

  background: #ffffff;
  border-radius: 30px;
}

.twoBar2 {
  position: absolute;
  width: 18px;
  height: 4px;
  left: 0px;
  top: 8px;

  /* Main/White */

  background: #ffffff;
  border-radius: 30px;
}

.twoBar3 {
  position: absolute;
  width: 36px;
  height: 4px;
  right: 4px;
  top: 0px;

  /* Main/White */

  background: #ffffff;
  border-radius: 30px;
}

.twoBar4 {
  position: absolute;
  width: 50px;
  height: 4px;
  left: 0px;
  top: 0px;

  /* Main/White */

  background: #ffffff;
  border-radius: 30px;
}

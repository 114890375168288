.planBody {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  width: 100%;
}
.planControls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 100%;
}

.plans {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}

.plan {
  display: flex;
  width: 388px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 20px;
  background: var(--main-white, #fff);
  flex-basis: 100%;
  flex: 1;
  cursor: pointer;
}

.planDisabled {
  display: flex;
  width: 388px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 20px;
  background:  rgba(188, 188, 188, 0.50);
  flex-basis: 100%;
  flex: 1;
  cursor: not-allowed;
}

.choiceItem {
  display: flex;
  padding: 11px 16px;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: var(--main-white, #fff);
  cursor: pointer;
}

.activeTab {
  background: var(--main-purple, #9747ff);
}

.planTabs {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 8px;
  overflow-x: auto;
}
.planList {
  /* display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  flex-wrap: wrap; */
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 12px;
  width: 100%;
}
@media (min-width: 600px) {
  .planList {
    grid-template-columns: repeat(1, 0fr);
  }
}
@media (min-width: 1000px) {
  .planList {
    grid-template-columns: repeat(2, 0fr);
  }
}
@media (min-width: 1400px) {
  .planList {
    grid-template-columns: repeat(3, 0fr);
  }
}
@media (min-width: 2000px) {
  .planList {
    grid-template-columns: repeat(4, 0fr);
  }
}
.frame4426 {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.frame4424 {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
}

.imagePlan {
  width: 118px;
  height: 154px;
  fill: linear-gradient(136deg, #fef0c7 0.31%, #ffe597 100%);
}
.imagePlan img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.imagePlanWithout {
  width: 118px;
  height: 154px;
  fill: linear-gradient(136deg, #fef0c7 0.31%, #ffe597 100%);
}
.imagePlanWithout img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: contrast(0.5);
}
.frame4392 {
  display: flex;
  align-items: flex-start;
  gap: 6px;
}
.frame4392Disabled {
  display: flex;
  align-items: flex-start;
  gap: 6px;
  filter: contrast(0.5);
}
.frame4390 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
}
.frame4384 {
  display: flex;
  padding: 6px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: var(--main-purple, #9747ff);
}
.frame4391 {
  display: flex;
  padding: 6px;
  align-items: flex-start;
  gap: 10px;
}
.frame43921 {
  display: flex;
  padding: 5px 6px;
  align-items: center;
  gap: 4px;
}
.borderBgPrimary {
  border-radius: 8px;
  background: var(--main-purple-tertiary, #f7f2fe);
}

.searchSVG > path {
  stroke: #b0b0b0;
}

.frame4518 {
  display: flex;
flex-direction: column;
align-items: flex-start;
gap: 12px;
align-self: stretch;


}
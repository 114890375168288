.flexColumn {
  display: flex;
  flex-direction: column;
}

.alignStart {
  align-items: flex-start;
}

.alignCenter {
  align-items: center;
}
.backgroundChip {
  background: #f7f2fe;
  border-radius: 100px;
  color: #9747ff;
  height: 20px;
}
.backgroundChip .MuiChip-avatar {
  margin-right: -16px !important;
  margin-left: 5px;
}
.backgroundChip img {
  height: 12px;
  width: 12px;
}
.backgroundChip span {
  font-size: 0.75rem;
}
.RadioOptionText {
  font-weight: 400;
  /* word-break: break-all; */
  align-items: center;
  text-align: start;
  width: 100%;
}
.actionLable {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.testListWrapper {
  margin-bottom: 18px;
}

.planFullScreen {
  display: flex;
  width: 100%;
  height: 100%;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 20px;
  background: var(--main-white, #fff);
}

.frame4426 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.frame4431 {
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  padding-bottom: 24px;
}

.emptyBox {
  display: flex;
  height: 280px;
  padding: 0px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 20px;
  background: linear-gradient(136deg, #fef0c7 0.31%, #ffe597 100%);
  overflow: hidden;
}

.frame4443 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.frame4424 {
  display: flex;
  padding: 20px 24px 24px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.frame4390 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}
.frame4392 {
  display: flex;
  align-items: flex-start;
  gap: 6px;
}

.frame4384 {
  display: flex;
  padding: 6px;
  align-items: flex-start;
  gap: 10px;
}

.frame4384 {
  display: flex;
  padding: 6px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: var(--main-purple, #9747ff);
}
.frame4391 {
  display: flex;
  padding: 6px;
  align-items: flex-start;
  gap: 10px;
}
.frame43921 {
  display: flex;
  padding: 5px 6px;
  align-items: center;
  gap: 4px;
}
.borderBgPrimary {
  border-radius: 8px;
  background: var(--main-purple-tertiary, #f7f2fe);
}

.buttonPlan {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 100px;
  background: var(--main-purple, #9747ff);
}

.buttonPlan:hover {
  background: #a159ff;
}

.outlinedButton {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border-radius: 100px;
  border: 1px solid var(--main-purple, #9747FF);
  background: var(--main-purple, #FFF);
}
.planBenefits {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 20px;
  background: var(--main-white, #fff);
}
.benefitList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.benefitItem {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 12px;
}
.frame4437 {
  display: flex;
  width: 44px;
  height: 44px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  background: var(--main-purple-tertiary, #f7f2fe);
}

.flexbuttonbox {
  display: flex;
  width: 100%;
  align-self: stretch;
  gap: 10px;
}
.progressBarWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* background: blue; */
}

.progress {
  height: 12px;
  border-radius: 1000px;
  background: #f7f2fe;
}

.progress span {
  background: #9747ff;
}

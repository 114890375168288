.backgroundChip {
  background: #f7f2fe;
  border-radius: 100px;
  color: #9747ff;
  height: 28px;
  cursor: pointer;
  max-width: max-content;
}
.backgroundChipContained {
  background: #9747ff;
  border-radius: 100px;
  color: #f7f2fe;
  height: 28px;
  cursor: pointer;
  max-width: max-content;
}

.backgroundChipContained:hover {
  background: #9747ffd6;
  border-radius: 100px;
  color: #f7f2fe;
  height: 28px;
  cursor: pointer;
  max-width: max-content;
}
.conatinerMain {
  display: flex;
  padding: 10px 40px 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 48px;
  background: #fcf9f5;
}
.conatinerFlourishMain {
  display: flex;
  padding: 10px 40px 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 48px;
  background: #fcf9f5;
  background-image: linear-gradient(to right, #ffd17245 , #83c88757);
}
.containerMenu {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.containerLogo {
  display: inline-flex;
  align-items: flex-start;
}
.containerLogoName {
  display: flex;
  height: 38px;
  padding: 12px;
  align-items: center;
  gap: 6px;
  border-radius: 12px;
  background: #9747ff;
}
.containerLogoFlourishName {
  display: flex;
  height: 38px;
  padding: 12px;
  align-items: center;
  gap: 6px;
  border-radius: 12px;
  background: transparent;
}
.containerLogoIcon {
  display: flex;
  width: 38px;
  height: 38px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 12px;
  background: #9747ff;
}
.containerLogoIcon1 {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 100px;
  background: var(--main-white, #fff);
}
.loginInfo {
  display: inline-flex;
  align-items: flex-start;
  gap: 12px;
}
.loginButton {
  display: flex;
  height: 38px;
  padding: 12px;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
}
.loginFlourishButton {
  display: flex;
  height: 38px;
  padding: 12px;
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  border-radius: 100px;
  border: 1px solid #000;
  background: #fff;
}
.joinButton {
  display: flex;
  height: 38px;
  padding: 12px;
  align-items: center;
  border-radius: 12px;
  background: #9747ff;
  cursor: pointer;
}
.bottomLoginButton {
  display: flex;
  height: 38px;
  padding: 12px;
  align-items: center;
  border-radius: 12px;
  background: #33AC19;
  cursor: pointer;
}
.joinFlourishButton {
  display: flex;
  height: 38px;
  padding: 12px;
  align-items: center;
  border-radius: 100px;
  background: #070d23;

  cursor: pointer;
}
.centerMenu {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
}

.contentLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 160px;
  width: 100%;
}
.bottomLayout {
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  border-radius: 40px 40px 0px 0px;
  background: #fff;
  width: 100%;
}
.bottomFlourishLayout {
  display: flex;
  padding: 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  border-radius: 32px;
  background: #070D23;
  width: 100%;
  margin-bottom: 40px;
}
.frame4451 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.frame4447 {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.frame4448 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.frame21 {
  display: inline-flex;
  height: 38px;
  padding: 12px;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #9747ff;
}
.shareViewConteiner {
  width: 600px;
  border-radius: 32px;
  background: var(--main-white, #fff);
  position: relative;
}
.background {
  width: 600px;
  flex-shrink: 0;
  position: absolute;
  z-index: 1;
}
.ellipse511 {
  width: 466.436px;
  height: 422.186px;
  flex-shrink: 0;
  border-radius: 466.436px;
  opacity: 0.7;
  background: #ffd172;
  filter: blur(252.5px);
  z-index: 1;
}
.ellipse515 {
  width: 466.436px;
  height: 422.186px;
  flex-shrink: 0;
  border-radius: 466.436px;
  opacity: 0.7;
  background: #ffc348;
  filter: blur(252.5px);
  z-index: 1;
}
.ellipse510 {
  width: 528.333px;
  height: 479.119px;
  flex-shrink: 0;

  border-radius: 528.333px;
  opacity: 0.7;
  background: #e5d5fc;
  filter: blur(252.5px);
  z-index: 1;
}

.ellipse516 {
  width: 528.333px;
  height: 479.119px;
  flex-shrink: 0;

  border-radius: 528.333px;
  opacity: 0.7;
  background: #e5d5fc;
  filter: blur(252.5px);
}
.ellipse517 {
  width: 528.333px;
  height: 479.119px;
  flex-shrink: 0;

  border-radius: 528.333px;
  opacity: 0.7;
  background: #e5d5fc;
  filter: blur(252.5px);
  z-index: 1;
}
.ellipse514 {
  width: 466.436px;
  height: 422.186px;
  flex-shrink: 0;

  border-radius: 466.436px;
  opacity: 0.7;
  background: #dbc1ff;
  filter: blur(252.5px);
  z-index: 1;
}
.frame3751 {
  display: inline-flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex-shrink: 0;
  /* background: var(--main-white, #fff); */
  z-index: 2;
}
.frame3747 {
  display: flex;
  padding: 20px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 24px;
  background: var(--main-white, #fff);
}
.frame3750 {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 24px;
  background: var(--main-white, #fff);
}
.frame3749 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.frame3748 {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}
.frame3745 {
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.rectangle {
  width: 100%;
  height: 1px;
  background: #f7f2fe;
}

.previousButton {
  width: 100%;
  border: 1px solid #9747ff;
  color: #9747ff;
  background: #f9f9f9;
  border-radius: 16px;
  padding: 4px 8px;
}

.previousButton:hover {
  border: 1px solid #9747ff;
  color: #9747ff;
  background: #ffffff;
}

.previousButton:disabled {
  border: none !important;
}

.nextButton:disabled {
  background: #f9f9f9;
  border: none !important;
}

.nextButton {
  width: 100%;
  border-color: #9747ff;
  background: #9747ff;
  border-radius: 16px;
  color: #ffffff;
  padding: 4px 8px;
}

.nextButton:hover {
  border: 1px solid #9747ff;
  color: #ffffff;
  background: #9747ff;
}

.progressBarWrapper {
  width: 100%;
  display: flex;
  margin-top: 24px;
  justify-content: center;
  gap: 8px;
}

.previousButtonNew {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;

  width: 100%;
  border: 1px solid #9747ff;
  color: #9747ff;
  background: #fff;
  border-radius: 100px;

  font-size: 16px !important;
  font-weight: 400;
  line-height: 100%; /* 16px */
  letter-spacing: -0.16px;
}

.previousButtonNew:hover {
  border: 1px solid #9747ff;
  color: #9747ff;
  background: #ffffff;
}

.previousButtonNew:disabled {
  border: none !important;
}

.nextButtonNew:disabled {
  background: #f9f9f9;
  border: none !important;
}

.nextButtonNew {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;

  width: 100%;
  border-color: #9747ff;
  background: #9747ff;
  border-radius: 100px;
  color: #ffffff;

  font-size: 16px !important;
  font-weight: 400;
  line-height: 100%; /* 16px */
  letter-spacing: -0.16px;
}

.nextButtonNew:hover {
  border: 1px solid #9747ff;
  color: #ffffff;
  background: #9747ff;
}

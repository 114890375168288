.quote {
  /* Quote */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 36px;
  gap: 20px;
  isolation: isolate;

  width: 100%;
  min-height: 158px;

  /* Main/Purple */

  background: #9747ff;
  border-radius: 20px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}

.flourishquote {
   /* Quote */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 36px;
  gap: 20px;
  isolation: isolate;

  width: 100%;
  min-height: 158px;

  /* Main/Purple */

  background: #33AC19;
  border-radius: 20px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}
.quotedescription {
  
    order: 2;
    height: auto;
    min-height: auto;
    margin-top: 24px;
    overflow-y: scroll;
  }
.quoteDataContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;

  /* width: 612px; */
  min-height: 52px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  z-index: 1;
}
.quoteDescriptionDataContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 16px;
  
    /* width: 612px; */
    min-height: 52px;
  
    /* Inside auto layout */
  
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    z-index: 1;
  }
  .quoteDataContentTextName {
    /* width: 612px; */
    min-height: 14px;
  
    /* Desktop/Title/h100 */
  
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 145%;
    /* or 26px */
  
    text-align: center;
    letter-spacing: -0.01em;
  
    /* Main/White */
  
    color: #ffffff;
  
    /* Inside auto layout */
  
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
.quoteDataContentText {
  /* width: 612px; */
  min-height: 52px;

  /* Desktop/Title/h100 */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 145%;
  /* or 26px */

  text-align: center;
  letter-spacing: -0.01em;

  /* Main/White */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.quoteDescriptionDataContentText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    /* or 27px */
    
    letter-spacing: -0.01em;
    
    /* Main/Black */
    
    color: #190D1A;
    white-space: pre-line;
  }

.quoteShareTitleChip {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 8px;

  width: 117px;
  height: 44px;

  background: #ac6cff;
  border-radius: 100px;
}

.quoteShareTitleChipText {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */
  width: 100%;
  justify-content: center;
  letter-spacing: -0.01em;

  /* Main/White */

  color: #ffffff;
}

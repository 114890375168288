* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: 'Coffee Mood';
  font-style: normal;
  font-weight: normal;
  src: local('Coffee Mood'), url('./assets/fonts/CoffeeMood-vmDDM.woff') format('woff');
}
body {
  font-family: "Inter", sans-serif;
}
::-webkit-scrollbar {
  width: 0px;
  border-radius: 20px;
}
/* div ::-webkit-scrollbar-track {
  background: #ebf2f1;
  border-radius: 10px;
}

::-webkit-scrollbar {
  overflow: auto;
  width: 4px;
  height: 4px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color:  #9747FF;
}

::-webkit-scrollbar-thumb:hover {
  background:  #9747FF;
} */
.MuiPickerStaticWrapper-content > div > div:nth-child(1) {
  display: none;
}

.highcharts-label-box {
  x: 0;
  y: 0;
}

.highcharts-legend-item.highcharts-series-0 > .highcharts-point {
  fill: #9747ff;
}
.highcharts-legend-item.highcharts-series-1 > .highcharts-point {
  fill: #ef8b6e;
}
.highcharts-series.highcharts-series-1.highcharts-column-series
  > .highcharts-point {
  fill: #f5ebe8;
}
.personalChart {
  width: 100% !important;
}
.personalChart .highcharts-series > .highcharts-point {
  fill: #ffffff;
  width: 0px;
}
.highcharts-axis-labels .highcharts-xaxis-labels text {
  color: green !important;
  fill: green !important;
  /* fill: #434243; */
}
/* .highcharts-scrolling{
  background: #9747ff;
  height: 0PX;
} */
.picker-container{
  height: 140px;
}
.text-3lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text-4lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.text-1lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-1line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.flexColumn {
  flex-direction: column;
}

.flex{
  display: flex;
}

.alignStart {
    align-items: flex-start;
}

.justifyCenter {
  justify-content: center;
}
.alignCenter {
    align-items: center;
}

.alignStrech {
  align-self: stretch;
}


.shareViewConteiner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.frame3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.frame3745 {
  display: flex;
  padding: 40px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.frame3744 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
}
.frame1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.frame2 {
  width: 100%;
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 16px;
  border: 1px solid var(--main-purple, #9747ff);
  background: var(--main-white, #fff);
}

.rectangle {
  width: 100%;
  height: 1px;
  flex-shrink: 0;
  background: var(--main-purple-tertiary, #f7f2fe);
}
.cards {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #f3f3f3;
  background: var(--main-white, #fff);
}
.tit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  position: relative;
}
.image {
  height: 140px;
  align-self: stretch;
  border-radius: 12px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.3) 100%
    ),
    lightgray 50% / cover no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
}
.createGoalsBtn {
  display: flex;
  padding: 19px 20px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  border: 1px solid #9747ff;
  color: #fff;
  background-color: #9747ff;
  border-radius: 100px;
  width: 100%;
}
.createGoalsBtn:hover {
  background-color: #a25bfe;
}
.desktop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}
.desktopText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
.desktopText1 {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.tagComponent {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  position: absolute;
  left: 12px;
  top: 12px;
}
.coinIconWrapper {
  width: max-content;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: #ffffff;
  border-radius: 100px;
  gap: 5px;
  height: 20px;
}
.coinIconWrapper img {
    width: 12px;
    height: 12px;
  }
.ml4 {
  margin-left: 4px;
}
.timeIcon {
  background: #ffffff;
  border-radius: 100px;
  padding: 4px 8px;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
}

.StepFiveWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}
.StepFiveSubWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  max-height: 390px;
}
.goalActionInput {
  margin: 12px 0px 32px 0px;
}
.goalActionInput
  .MuiInputBase-formControl:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: #e5d5fc !important;
  border-width: 1px;
}
/* button */
.previousButton {
  padding: 18px 20px;
  width: 100%;
  border: 1px solid #9747ff;
  color: #9747ff;
  background: #f9f9f9;
  border-radius: 16px;
}

.previousButton:hover {
  border: 1px solid #9747ff;
  color: #9747ff;
  background: #ffffff;
}

.previousButton:disabled {
  border: none !important;
}

.nextButton:disabled {
  background: #f9f9f9;
  border: none !important;
}

.nextButton {
  padding: 18px 20px;
  width: 100%;
  border-color: #9747ff;
  background: #9747ff;
  border-radius: 16px;
  color: #ffffff;
}

.nextButton:hover {
  border: 1px solid #9747ff;
  color: #ffffff;
  background: #9747ff;
}

.progressBarWrapper {
  width: 100%;
  display: flex;
  margin-top: 24px;
  justify-content: center;
  gap: 8px;
}
.progressTittle {
  width: 123px;
  text-align: center;
  color: #000000;
}
.customActionLevelGroup {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 4px;
  align-self: stretch;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.customActionLevelGroup  button {
  border: none;
  gap: 5px;
  font-size: 0.85rem !important;
  margin: 5px 3px;
}
.toggleButton {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 12px;
  background: #f9f9f9;
  color: #190d1a;
  fill: #190d1a;
  stroke: #190d1a;
}
.selectedtoggleButton path {
  fill: #9747FF;
}
.selectedtoggleButton {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 72px 0px rgba(25, 13, 26, 0.04);
  color: #9747ff;
  fill: #9747ff;
  stroke: #9747ff;
  background-color: #fff !important;

}
.toggleButton path {
  fill: #190D1A;
}
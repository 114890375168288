.subAccordianSummery {
  margin: 0px 0px !important;
  padding: 0px 1px !important;
  min-height: 20px !important;
}
.GrowthAccordianDetailContainer {
  padding: 0px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.GrowthAccordianDetailContainerBox {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 16px;
  background: #fff;
  margin-top: 20px;
}
.customRadioActionGroup {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  gap: 12px;
  border-radius: 16px;
  border: 1px solid var(--main-stroke, #d0ced0);
  background: var(--main-white, #fff);
}
.customRadioActionAction {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  justify-content: space-between;
}
.customRadioAction {
  /* width: 100%;
  display: flex;
  padding: 0 11.5px;
  margin: 12px 0px;
  position: relative;
  width: 100%;
  display: flex;
  border: 1px solid #f3f3f3;
  border-radius: 16px;
  padding: 11.5px;
  margin: 12px 0px;
  position: relative; */
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid var(--main-stroke, #d0ced0);
  background: var(--main-white, #fff);
}
.GrowthAccordianDetail {
  width: 100%;
  border: 1px solid #f3f3f3;
  padding: 16px;
  border-radius: 12px;
  margin: 12px 0px;
}
.customRadio {
  width: 100%;
  display: flex;
  border: 1px solid #f3f3f3;
  border-radius: 16px;
  justify-content: space-between;
  padding: 16px;
  margin: 12px 0px;
}
.checkBoxText {
  color: #190d1a;
  font-size: 14px !important;
  line-height: 145%; /* 20.3px */
  letter-spacing: -0.14px;
}
.schedualWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
}

.actionNameWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  position: relative;
}
.dataNotFoundSubWrapper {
  display: flex;
  justify-content: center;
}
.PlusIconWrapper {
  display: flex;
  align-items: center;
}
.selectedWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.checkBoxIcon {
  width: 12px;
  height: 12px;
  margin: 0px 4px;
  cursor: pointer;
}
.checkBoxChekedText {
  color: #767476;
  font-size: 14px;
  text-decoration: line-through;
}
.duplicateTextWrapper {
  justify-content: center;
  width: 56px;
  height: 28px;
  border-radius: 16px;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  gap: 4px;
}
.backgroundChip {
  background: #f7f2fe;
  border-radius: 100px;
  color: #9747ff;
  height: 20px;
}
.backgroundChip .MuiChip-avatar {
  margin-right: -16px;
}
.backgroundChip img {
  height: 12px;
  width: 12px;
}
.backgroundChip span {
  font-size: 0.75rem;
}

.recommendedClass {
  color: #9747ff;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: space-between;
}

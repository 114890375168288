.friendsResultBlankContainer {
  border-radius: 24px;
  border: 1px solid  #f3f3f3;
  background:  #fff;
  display: flex;
  padding:  1.5rem 1.5rem;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  width: 100%;
}

.friendsResultContent {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.friendsResultDataContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}
.imagesBlock {
  display: block;
  width: 100%;
  position: relative;
  height: 160px;
}
.planBody {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 20px;
  background: #fff;
  width: calc(100% - 20rem);
  height: 100%;
  padding-bottom: 24px;
}

.titleBox {
  display: flex;
  padding: 24px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid #f3f3f3;
}

.searchBox {
  display: flex;
  padding: 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid #f3f3f3;
}

.searchBotton {
  display: flex;
  padding: 19px 20px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 100px;
  background: var(--main-purple, #9747ff);
}
.searchSVG > path {
  stroke: #b0b0b0;
}

.journalsBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.plansBox {
  display: flex;
  padding: 24px 24px 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.quote {
  display: flex;
  padding: 24px 64px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
  border-radius: 20px;
  background: var(--main-purple, #9747ff);
  position: relative;
}
.dataContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  z-index: 1;
}
.frame4516 {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  background: #ac6cff;
}
.planDayData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 20px;
  border: 2px solid #f3f3f3;

  background: var(--main-white, #fff);
}
.frame4542 {
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-bottom: 1px solid #f3f3f3;
}
.frame4518 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}
.frame4439 {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 2px solid var(--main-purple, #9747ff);
  background: var(--main-white, #fff);
}
.titleDataContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}
.bodyDataContent {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.backgroundChip {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: var(--main-purple-tertiary, #f7f2fe);
  height: auto;
}
.frame4540 {
  display: flex;
  padding: 0px 16px 16px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.oprnNoteButton {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  background: var(--main-purple, #9747ff);
}
.actionBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid #f3f3f3;
  background: var(--main-white, #fff);
}
.actionHeaderBox {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid #eee;
}
.actionHeaderTitle {
    display: flex;
align-items: center;
gap: 8px;
flex: 1 0 0;
}

.modalTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 135%;
  /* or 32px */

  letter-spacing: -0.01em;

  /* Main/Black */

  color: #190d1a;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  word-wrap: break-word;
  word-break: break-word;
  display: -webkit-box;
  width: 100%;
}
.modalButtonDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalButton {
  background: #9747ff;
  border-radius: 100px;
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
}
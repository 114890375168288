.dashbordTitleContainer {
  display: flex;
  flex-direction: column;
  background-color: #ffff;
  padding: 24px;
  border-radius: 20px;
  gap: 12px;
}

.dashbordTitle {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dashbordTitlebody {
  display: flex;
  flex-direction: column;
}
.mainWrapper {
  /* height: calc(100vh - 230px); */
  overflow: auto;
  margin-top: 16px;
  background-color: #ffffff;
  padding: 24px;
  border-radius: 20px;
  height: auto;
}
.accodianOpenMainWrapper {
  height: auto;
  margin-top: 16px;
  background-color: #ffffff;
  padding: 24px;
  border-radius: 20px;
  margin-bottom: 16px;
}
.smallMainWrapper {
  height: calc(100vh - 300px);
  overflow: auto;
  margin-top: 16px;
  background-color: #ffffff;
  padding: 12px;
  border-radius: 20px;
}
.accodianOpensmallWrapper {
  height: auto;
  /* overflow: auto; */
  margin-top: 16px;
  background-color: #ffffff;
  padding: 12px;
  border-radius: 20px;
}

/* accordian */

.AccordianSummery {
  margin: 0px 0px !important;
}
.schedualScoreWrapper {
  display: flex;
  gap: 20px;
  align-items: center;
}
.goalScoreWrapper {
  width: 100%;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: center;
}

/* subAccordian */
.subAccordianSummery {
  margin: 0px 0px !important;
  padding: 0px 1px !important;
  min-height: 20px !important;
}

.shedualdate {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid #f7f2fe;
}
.shedualdateText {
  color: #9747ff;
  display: flex;
  padding: 8px 12px;
}

.plusImage {
  display: flex;
  padding: 10px;
}
.customRadioAction {
  width: 100%;
  display: flex;
  border: 1px solid #f3f3f3;
  border-radius: 16px;
  padding: 16px;
  margin: 12px 0px;
}
.schedualWrapper {
  display: flex;
  gap: 15px;
}

.actionNameWrapper {
  display: flex;
  width: 100%;
}
.dataNotFoundSubWrapper {
  display: flex;
  justify-content: center;
}
.createMoreGoalWrapper {
  width: 44px;
  height: 44px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  bottom: 35px;
  right: 80px;
}
.closeIcon {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
}
.cel_title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 150px;
  gap: 10px;
  isolation: isolate;

  width: 100%;
  position: relative;
  height: 160px;
}
.cel_title_icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 96px;
  height: 96px;

  /* Main/Purple-Tertiary */

  background: #f7f2fe;
  border-radius: 100px;
}
.cel_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 150px;
  gap: 24px;
  width: 100%;
  position: relative;
}
.cel_info_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 135%;
  /* or 32px */

  text-align: center;
  letter-spacing: -0.01em;

  /* Main/Black */

  color: #190d1a;
}

.cel_info_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 6px;

  width: 100%;
  height: 48px;

  /* Main/Purple */

  background: #9747ff;
  border-radius: 100px;
}

.divider {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  height: 1px;
  background: #E8E8E8;
}

.modalContent {
  padding: 0 !important;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  width: 100%;
  height: 40rem;
}

.video {
  display: flex;
  width: 80rem;
  height: 40rem;
}

.videoContent {
  display: flex;
  padding: 24px 32px 32px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  position: absolute;
  border-radius: 1.3125rem;
  bottom: 5.5rem;
  gap: 0.75rem;
  left: 1.5rem;
  padding: 2rem;
  background: var(--main-white, #FFF);
  width: 25.25rem;
  
}

.testVideo {
}
.closeIconeWrapper {
  position: absolute;
  top: -15px;
  right: 0;
  cursor: pointer;
}
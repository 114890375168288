.headerImageDiv {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  isolation: isolate;

  /* width: 788px; */
  height: 78px;

  border-radius: 0px 20px 20px 0px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 24px;
}

.headerImageBox {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 8px;
  gap: 10px;
  position: relative;
  width: 108px;
  height: 74px;
  /* background: linear-gradient(136.93deg, #C7FFD3 0%, #47EF64 104.22%); */
  /* Main/Purple */
  background: linear-gradient(
      180deg,
      rgba(25, 13, 26, 0) 0%,
      rgba(25, 13, 26, 0.73) 87.32%
    ),
    #ffffff;
  border: 1px solid #9747ff;
  border-radius: 14px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
}

.dailySchedule {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  /* Main/White */
  background: #ffffff;
  border-radius: 20px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: 12px;
}

.dailyScheduleTitle {
  /* width: 740px; */
  height: 26px;
  /* Desktop/Title/h100 */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 145%;
  /* or 26px */
  letter-spacing: -0.01em;
  /* Main/Black */
  color: #190d1a;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 20px;
}

.headerImageText {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height, or 10px */
  letter-spacing: -0.01em;
  /* Main/White */
  color: #ffffff;
}

.dailyscheduleLayout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.streakWeek {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  /* width: 740px; */
  overflow-x: scroll;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.actionGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 100%;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.streakDay {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 36px;
  height: 56px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.streakDateDay {
  width: 36px;
  height: 12px;

  /* Desktop/Button/Primary XS • 400 • 12 */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */

  text-align: center;
  letter-spacing: -0.01em;

  /* Main/Dark Gray */

  color: #434243;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.streakDate {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;
  width: 36px;
  height: 36px;
  /* Main/White */
  background: #ffffff;
  color: #434243;
  /* Main/Stroke
    For Stroke
    */
  border: 1px solid #f3f3f3;
  border-radius: 100px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  cursor: pointer;
}

.streakDateText {
  /* Desktop/Button/Primary S • 400 • 14 */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  /* or 14px */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  /* Main/Dark Gray */
  /* color: #434243; */
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
}
.blurImage {
  position: absolute;
  width: 257px;
  height: 200px;
  right: -198px;
  top: -61px;

  background: #fef8f3;
  filter: blur(32px);
}

.noiceTargetGoal {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */

  letter-spacing: -0.01em;

  /* Main/Black */

  color: #434243;
}

.noiceTargetCurrent {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */

  letter-spacing: -0.01em;

  /* Main/Black */

  color: #190d1a;
}
.actionGroupBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 100%;
  /*  height: 136px; */

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.scheduleAction {
  /* Schedule-Action */
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;
  width: calc(100% - 100px);
  /* height: 136px; */

  /* Main/White */
  background: #ffffff;
  /* Main/Stroke

    For Stroke
    */
  border: 1px solid #f3f3f3;
  border-radius: 16px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
}

.scheduleActionDataContent {
  /* DataContent */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
  /*height: 60px; */

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.scheduleActionButton {
  height: 28px;
  background: #9747ff;
  border-radius: 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  /* Main/White */

  color: #ffffff;
}

.loadMoreButton {
  height: 38px;
  width: 100%;
  padding: 12px 16px;
  background: #9747ff;
  border-radius: 100px;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
}

.scheduleActionText {
  width: 100%;
  /* height: 24px; */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  letter-spacing: -0.01em;

  color: #190d1a;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.scheduleActionDataContentData {
  /* DataContent */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  height: 28px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  width: 100%;
  justify-content: space-around;
}

.scheduleActionDataNameContentData {
  /* DataContent */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  /* width: 524px; */
  height: 28px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}

.scheduleActionDataTagContentData {
  /* Tags */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;

  /* width: 144px; */
  height: 20px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  justify-content: flex-end;
}

.scheduleActionDataContentTitle {
  /* width: 488px; */
  height: 22px;

  /* Desktop/Title/h75 */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  /* or 22px */

  letter-spacing: -0.01em;

  /* Main/Black */

  color: #190d1a;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
}

.backgroundChip {
  background: #f7f2fe;
  border-radius: 100px;
  color: #9747ff;
  font-size: 12px;
  font-weight: 400;
  padding: 4px 8px;
  gap: 4px;
}
.backgroundChip span {
  padding: 0;
}

.backgroundChip img {
  height: 12px;
  width: 12px;
}
.backgroundChip img {
  height: 12px;
  width: 12px;
}

.avatarClass {
  height: 12px !important;
  width: 12px !important;
  margin: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.todayDate {
  background: #9747ff;
  color: #ffffff;
}

.selectedDate {
  border-color: #9747ff;
  color: #9747ff;
}

.quote {
  /* Quote */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 88px;
  gap: 20px;
  isolation: isolate;

  /* width: 788px; */
  min-height: 158px;

  /* Main/Purple */

  background: #9747ff;
  border-radius: 20px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;

  margin-top: 12px;
}

.quoteDataContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;

  /* width: 612px; */
  min-height: 52px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  z-index: 1;
}

.quoteDataContentTextName {
  /* width: 612px; */
  min-height: 14px;

  /* Desktop/Title/h100 */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  /* or 26px */

  text-align: center;
  letter-spacing: -0.01em;

  /* Main/White */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.quoteDataContentText {
  /* width: 612px; */
  min-height: 52px;

  /* Desktop/Title/h100 */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 145%;
  /* or 26px */

  text-align: center;
  letter-spacing: -0.01em;

  /* Main/White */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.quoteDataContentButtons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 13px;

  /* width: 197px; */
  height: 38px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  z-index: 2;
}

.quoteDataContentButton {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 6px;

  /* width: 88px; */
  height: 38px;

  background: #ac6cff;
  border-radius: 100px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.quoteDataContentButton:hover {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 6px;

  /* width: 88px; */
  height: 38px;

  background: #ac6cffb0;
  border-radius: 100px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.quoteDataContentButtonText {
  /* width: 38px; */
  height: 14px;

  /* Desktop/Button/Primary S • 400 • 14 */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  /* Main/White */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.acticityCounterContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 100%;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.acticityCounterContainerTiles {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  /* gap: 12px; */
  justify-content: space-between;
  /* width: 388px; */
  width: 100%;
  min-height: 80px;
  height: auto;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.premiumCTA {
  /* width: 388px; */
  height: 376px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.mainLayout {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;
  /* height: 946px; */
  top: 64px;
}
.noviceCounter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;

  /* Main/White */
  width: 100%;
  background: #ffffff;
  border-radius: 16px;
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0;
}

.noviceDataContain {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 12px;
  width: 100%;
  justify-content: space-between;
  /* width: 356px;
height: 40px; */
}

.noviceDataContainTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
}
.noviceIcon {
  width: 36px;
  height: 36px;

  /* Main/White */

  background: #ffffff;
  border-radius: 100px;
}
.noviceTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  /* identical to box height, or 18px */

  letter-spacing: -0.01em;

  /* Main/Black */

  color: #190d1a;
}

.noviceTitle1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 14px */

  letter-spacing: -0.01em;

  /* Main/Dark Gray */

  color: #434243;
}

.acticityCounter {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 10px;
  gap: 14px;
  width: 85%;
  height: 80px;

  /* Main/White */

  background: #ffffff;
  border-radius: 16px;
  width: 49%;
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.acticityCounterContain {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;

  /* width: 156px; */
  height: 16px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.acticityCounterContainTitle {
  /* width: 112px; */
  height: 16px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */

  letter-spacing: -0.01em;

  /* Main/Dark Gray */

  color: #434243;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
  align-items: center;
  display: flex;
  gap: 5px;
  justify-content: space-between;
}

.acticityCounterContainResult {
  /* width: 156px; */
  height: 18px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  /* identical to box height, or 18px */

  letter-spacing: -0.01em;

  /* Main/Black */

  color: #190d1a;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  padding-left: 3px;
}

.premiumCTAContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 20px;
  /* width: 348px; */
  height: 160px;
  left: 20px;
  top: 196px;
}

.premiumCTAOuter {
  position: relative;
  width: 311px;
  height: 150px;
  left: 39px;
  top: -50px;
  background: #ffffff;
  border-radius: 28px;
  border: 8px solid rgba(255, 255, 255, 0.4);
  box-shadow: 0px 2px 0px 10px rgba(252, 252, 252, 0.4);
}

.premiumText {
  position: absolute;
  padding: 6px;
  gap: 10px;
  height: 22px;
  /* width: 55px; */
  left: 115px;
  top: 40px;
  border-radius: 6px;
  font-size: 10px;
  line-height: 100%;
  color: white;
  /* Main/Purple */
  background: #9747ff;
}

.premiumScientific {
  width: 247px;
  height: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  /* identical to box height, or 20px */
  text-align: center;
  letter-spacing: -0.01em;
  /* Main/Purple */
  color: #9747ff;
  position: absolute;
  left: 30px;
  top: 75px;
}

.premium30DayFree {
  width: 234px;
  height: 18px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;

  /* identical to box height, or 18px */
  text-align: center;

  /* Main/Black */
  color: #190d1a;
  position: absolute;
  left: 30px;
  top: 100px;
}

.premiumGive30DayFree {
  width: 348px;
  height: 32px;
  /* Desktop/Title/h150 */
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 135%;
  /* or 32px */
  text-align: center;
  letter-spacing: -0.01em;
  /* Main/White */
  color: #ffffff;
  /* Inside auto layout */
  position: relative;
  /* left: 20px; */
  /* top: 60px; */
  text-align: center;
  width: 100%;
  top: -14px;
}

.premiumRectDesc {
  width: 348px;
  height: 48px;

  /* Desktop/Paragraph/P1 • 400 • 16 */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;

  /* or 24px */
  text-align: center;
  letter-spacing: -0.01em;

  /* Main/White */
  color: #ffffff;
  position: relative;
  /* left: 20px; */
  /* top: 60px; */
  width: 100%;
  top: 0px;
  padding: 0 20px;
}

.premiumCTARectangle {
  width: 388px;
  height: 350px;
  top: 13px;
  /* Main/Purple */
  background: #9747ff;
  border-radius: 20px;
  position: relative;
}

.premiumButton {
  border-radius: 100rem;
  padding: 1rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #9747ff;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 3px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(101deg, #e6f8de, #fff8bd);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  height: 52px;
  width: 148px;
  left: 120px;
  top: 0;
  position: absolute;
}

@media only screen and (max-width: 767px) {
  .premiumCTARectangle {
    top: 48px;
  }
}

/* Modal Styles */

.modalTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 135%;
  /* or 32px */

  letter-spacing: -0.01em;

  /* Main/Black */

  color: #190d1a;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  word-wrap: break-word;
  word-break: break-word;
  display: -webkit-box;
  width: 100%;
}
.modalButtonDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalButton {
  background: #9747ff;
  border-radius: 100px;
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
}

.linktocopy .MuiSnackbarContent-root {
  background-color: #fff;
}

.calendarIcon {
  position: absolute;
  width: 24px;
  height: 28px;
  left: 6px;
  top: 6px;

  background: #ffffff;
  border-radius: 4px 4px 0px 0px;
}

.scheduleDataTitle {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.incompleteCell {
  color: red;
  border: 1px solid red;
}

.selectedDate.incompleteCell {
  border-color: #9747ff;
  color: #9747ff;
  border: 1px solid #9747ff;
}

.strike {
  /* position: relative; */
  position: relative;
  text-decoration: none;
  background-image: linear-gradient(black, black);
  background-repeat: no-repeat;
  background-position: 0% 50%;
  /* background-size: 100% 1px; */
  background-size: 0% 2px;
  transition: background-size 2s ;
}

.completedStrike {
  background-size: 100% 2px;
}
.readMore{
    color: #9747FF;
    cursor: pointer;
    line-height: 145%;
  }
  

li{
  margin-left: 20px;
}
.description span {
  color: #434243 !important;
}